/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  Feature,
  Polygon,
  Geometry,
  GeometryCollection,
  LineString,
  MultiPolygon,
  Point,
} from "geojson";
import type { EventData } from "mapbox-gl";

export type MapboxMouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
} & EventData;

export type PointFeature<T = any> = Feature<Point, T>;

export type PolygonFeature<T = any> = Feature<Polygon, T>;

export type MultiPolygonFeature<T = any> = Feature<MultiPolygon, T>;

export type LineStringFeature<T = any> = Feature<LineString, T>;

export const isGeometry = (geometry: any): geometry is Geometry => {
  return !!geometry?.type && !!geometry.coordinates;
};

export const isGeometryCollectionGeometry = (
  geometry: Geometry | undefined,
): geometry is GeometryCollection => {
  return geometry?.type === "GeometryCollection";
};

export const isPointFeature = (
  feature: Feature | undefined,
): feature is PointFeature => {
  return isPointGeometry(feature?.geometry);
};

export const isPointGeometry = (
  geometry: Geometry | undefined,
): geometry is Point => {
  return geometry?.type === "Point";
};

export const isGeoJsonSource = (
  source: mapboxgl.AnySourceImpl | maplibregl.Source | null | undefined,
): source is mapboxgl.GeoJSONSource | maplibregl.GeoJSONSource => {
  return !!source && source.type === "geojson";
};

export const isPolygonFeature = (
  feature: Feature | undefined,
): feature is PolygonFeature => {
  return feature?.geometry.type === "Polygon";
};

export const isMultiPolygonFeature = (
  feature: Feature | undefined,
): feature is MultiPolygonFeature => {
  return feature?.geometry.type === "MultiPolygon";
};

export const isLineStringFeature = (
  feature: Feature | undefined,
): feature is LineStringFeature => {
  return feature?.geometry.type === "LineString";
};
